<template>
    <div>
        <div style="height:52px">
            <!--2023-8-29:动态检测原始数据：去掉删除按钮-->
            <!--<el-button size="small" type="danger" class="btnDelete" icon="el-icon-delete" @click="handleDelete()">删除</el-button>-->

            <el-button class="btnSearch" size="small" type="primary" @click="fixPlateNo()" icon="el-icon-setting">编码更正</el-button>
            <el-button class="btnSearch" size="small" type="primary" @click="doSearch()" icon="el-icon-search">搜索</el-button>
            <el-input v-model="queryForm.query" placeholder="" class="inputSearch" @keyup.enter.native="doSearch"></el-input>
            <el-select size="small" class="btnSearch" v-model="queryForm.queryLabel" placeholder="">
                <el-option
                    v-for="item in queryLabelList"
                    :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </div>
        <div style="hegith:20px;margin-bottom:5px">
      <span style="font-size:16px;margin-left:15px;">
        <b>机器编码</b>:  {{dataInfo.lineno}}
        <b class="fontLabel">产品编码</b>:  {{dataInfo.plateno}}
        <b class="fontLabel">时间</b>:  {{dataInfo.date}}
        <b class="fontLabel">MAX</b>:  {{dataInfo.max}}
        <b class="fontLabel">MAX NO</b>:  {{dataInfo.maxno}}
      </span>
        </div>

        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" :height="tableHeight">
            <el-table-column prop="maxn" label="MAX-N"> </el-table-column>
            <el-table-column prop="max" label="MAX"></el-table-column>
            <el-table-column prop="max1" label="No.1"></el-table-column>
            <el-table-column prop="max2" label="No.2"></el-table-column>
            <el-table-column prop="max3" label="No.3"></el-table-column>
            <el-table-column prop="max4" label="No.4"></el-table-column>
            <el-table-column prop="max5" label="No.5"></el-table-column>
            <el-table-column prop="max6" label="No.6"></el-table-column>
            <el-table-column prop="max7" label="No.7"></el-table-column>
            <el-table-column prop="max8" label="No.8"></el-table-column>
            <el-table-column prop="max9" label="No.9"></el-table-column>
            <el-table-column prop="max10" label="No.10"></el-table-column>
        </el-table>
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="26"
            layout="prev, pager, next, jumper"
            :total="Number(totalCount)">
        </el-pagination>
        <el-dialog title="编码更正" :visible.sync="fixPlateNoDialogVisible" width="60%" :before-close="handleClose">
            <el-row>
                <el-col :span="24" :offset="0" style="height: 50px">
<!--                    <el-date-picker size="small" style="margin-right: 1vw"-->
<!--                                    v-model="dialogDatePair" @change="changeDialogDate()"-->
<!--                                    type="daterange"-->
<!--                                    format="yyyy-MM-dd"-->
<!--                                    value-format="yyyy-MM-dd HH:mm:ss"-->
<!--                                    range-separator="至"-->
<!--                                    start-placeholder="开始日期"-->
<!--                                    end-placeholder="结束日期">-->
<!--                    </el-date-picker>-->
                    <el-date-picker
                        v-model="dialogDate" @change="changeDialogDate()"
                        size="small"
                        type="date"
                        placeholder="请选择日期"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                    <el-select size="small" v-model="dialogEquipNo" @change="changeDevice()" filterable clearable placeholder="请选择设备">
                        <el-option v-for="item in queryEquipList" :key="item.id" :label="item.equipNo" :value="item.equipNo">
                        </el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" :offset="0">
                    <el-table ref="multipleTable" tooltip-effect="dark" :data="fixPlateNoDialogTableData"
                              @sort-change="changeDialogTableSort"
                              style="width: 100%;" :height="dialogTableHeight" >
                        <el-table-column prop="plateno" label="阴极板编号"  show-overflow-tooltip>
                            <template slot-scope="scope">
                                <el-input
                                          v-model="scope.row.plateno">
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="max" label="平面度" show-overflow-tooltip></el-table-column>
                        <!--暂时采用默认排序-->
                        <el-table-column prop="datatime" label="创建时间" sortable=""
                                         :formatter="dateTimeFormatter" show-overflow-tooltip></el-table-column>
<!--                        <el-table-column prop="datatime" label="创建时间"-->
<!--                                         :formatter="dateTimeFormatter" show-overflow-tooltip></el-table-column>-->

                    </el-table>
                    <el-pagination
                        @size-change="handleDialogSizeChange"
                        @current-change="handleDialogCurrentChange"
                        :current-page="dialogCurrentPage"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="10"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="Number(dialogTotalCount)">
                    </el-pagination>
                </el-col>
            </el-row>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="updateFixedPlateNo()">提 交</el-button>
                <el-button @click="fixPlateNoDialogVisible = false;">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {fDateFormat, fDateTimeFormat} from "@/utils/utilsset";
import {sanyouAxios} from "@/api";

export default {
    name: "attachmanage",
    data() {
        return {
            currentUserId:"",
            //查询条件
            queryForm:{
                query:"",
                queryLabel:"plateno"
            },
            queryLabelList:[{
                value:"plateno",
                label:"产品编码"
            },{
                value:"lineno",
                label:"机器编码"
            }],
            //表格数据
            tableData:[],
            dataInfo:{
                lineno:"",
                plateno:"",
                date:"",
                max:"",
                maxno:""
            },
            //表格高度
            tableHeight:500,
            //分页
            currentPage:1,
            totalCount:0,
            // 编码更正
            fixPlateNoDialogVisible:false,
            //编码更正-数据
            fixPlateNoDialogTableData:[],
            //编码更正-分页
            dialogPageSize:10,
            dialogCurrentPage:1,
            dialogTotalCount:0,
            dialogTableHeight:400,
            //编码更正-传入参数
            dialogDate:'',
            dialogDatePair:[],
            dialogStartTime:'',
            dialogEndTime:'',
            dialogEquipNo:'',
            queryEquipList:[],
            //编码更正-排序
            sortMethod:'DESC',
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.tableHeight = window.innerHeight - this.$refs.multipleTable.$el.offsetTop - 220;
            window.onresize = () => {
                this.tableHeight = window.innerHeight - this.$refs.multipleTable.$el.offsetTop - 220;
            };
            //加载时间
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth();
            //let month = Number(date.getDate())!==1?date.getMonth():date.getMonth()-1;
            let day = date.getDate();
            // this.dialogStartTime = fDateTimeFormat(new Date(year,Number(date.getDate())!==1?month:month-1,1));
            // this.dialogEndTime = fDateTimeFormat(new Date(year,month,day));
            // this.dialogDatePair=[]
            // this.dialogDatePair.push(new Date(year,Number(date.getDate())!==1?month:month-1,1));
            // this.dialogDatePair.push(new Date(year,month,day));
            this.dialogDate=fDateFormat(new Date(year,month,day));
            this.dialogStartTime=this.dialogDate+' 00:00:00'
            this.dialogEndTime=this.dialogDate+' 23:59:59'
            //加载设备
            this.loadEquipList()
        });
    },
    methods: {
        //格式化时间
        dateTimeFormatter(row, column, cellValue){
            return fDateTimeFormat(cellValue)
        },
        //页码变更
        handleCurrentChange: function(val) {
            this.currentPage = val;
            this.loadData(val);
        },
        //加载数据
        loadData(page){
            let _this = this;
            if(page == null || isNaN(page))
                page = 1;

            sanyouAxios({
                headers:{
                    userId:this.currentUserId
                },
                method: 'POST',
                url:'/industryData/queryData?page=' + page+'&equipType=2',
                data:{
                    lineno:"en02",
                    userId:this.currentUserId,
                    queryLabel:_this.queryForm.queryLabel,
                    query:_this.queryForm.query
                }
            })
                .then(res => {
                    if(res.data.status === 200){
                        let data = res.data.data;
                        _this.tableData = data.list;
                        _this.totalCount = data.records;

                        _this.dataInfo.lineno = data.lineno;
                        _this.dataInfo.plateno = data.plateno;
                        _this.dataInfo.date = data.date;
                        _this.dataInfo.max = data.max;
                        _this.dataInfo.maxno = data.maxno;
                    }else{
                        this.$message({
                            type: 'warning',
                            duration: 2000,
                            message: '没有权限查看!'
                        });
                    }
                });
        },
        //查询
        doSearch(){
            this.loadData(1);
        },
        //删除
        handleDelete(){
            this.$confirm('此操作将删除检测数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let deleteForm = {
                    datatime:this.dataInfo.date,
                    plateno:this.dataInfo.plateno,
                    lineno:this.dataInfo.lineno
                };
                sanyouAxios({
                    headers:{
                        userId:this.currentUserId
                    },
                    method: 'POST',
                    url: '/industryData/deleteData',
                    data: deleteForm
                })
                    .then(res => {
                        if(res.data.status === 200){
                            this.$message({
                                type: 'success',
                                duration: 2000,
                                message: '删除成功!'
                            });
                            let currentPage = this.currentPage;
                            this.loadData(currentPage);
                        }else{
                            this.$message({
                                duration: 2000,
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 2000,
                    message: '已取消删除'
                });
            });
        },
        // 加载错误编码
        loadZeroPlateNo(){
            //   /industryData/industryDataWithoutProductNo?equipNo=en02&startTime=&endTime=&size=10&page=1
            sanyouAxios({
                //headers:{
                //    userId:this.currentUserId
                //},
                method: 'GET',
                url: '/industryData/industryDataWithoutProductNo',
                params: {
                    equipNo: this.dialogEquipNo,
                    startTime:this.dialogStartTime,
                    endTime:this.dialogEndTime,
                    size:this.dialogPageSize,
                    page:this.dialogCurrentPage,
                    //排序参数
                }
            }).then(res => {
                if(res.data.status === 200){
                    //弹窗
                    let data = res.data.data || [];
                    this.fixPlateNoDialogVisible=true;

                    this.fixPlateNoDialogTableData=data.rows;
                    this.dialogTotalCount = data.records;
                }else{
                    this.$message({
                        type: 'warning',
                        duration: 2000,
                        message: res.data.msg,
                    });
                }
            });
        },
        //加载编码更正弹窗
        fixPlateNo(){
            //加载弹窗内容
            this.loadZeroPlateNo()
        },
        //批量更新
        updateFixedPlateNo(){
            //过滤
            let fixedData=this.fixPlateNoDialogTableData.filter((item)=>{
                return Number(item['plateno']) !== 0
            })
            //console.log(fixedData)

            sanyouAxios({
                // headers:{
                //     userId:this.currentUserId
                // },
                method: 'POST',
                url: '/industryData/updateProductNoOfIndustryData',
                data: fixedData
            }).then(res => {
                if(res.data.status === 200){
                    //提示
                    this.$message({
                        duration: 2000,
                        message: res.data.data,
                        type: "success",
                    });
                    //关闭弹窗
                    this.fixPlateNoDialogVisible=false;
                }else{
                    this.$message({
                        type: 'warning',
                        duration: 2000,
                        message: res.data.msg,
                    });
                }
            });
        },
        //监听日期选择器更变
        changeDialogDate(){
            // if(this.dialogDatePair != null && this.dialogDatePair.length > 0){
            //     this.dialogStartTime=this.dialogDatePair[0];
            //     this.dialogEndTime=this.dialogDatePair[1];
            //
            //     this.loadZeroPlateNo();
            // }
            if (this.dialogDate){
                //2023-08-01 00:00:00
                this.dialogStartTime=this.dialogDate+' 00:00:00'
                this.dialogEndTime=this.dialogDate+' 23:59:59'
                this.loadZeroPlateNo();
            }
        },
        changeDevice() {
            this.loadZeroPlateNo();
        },
        //获取用户所拥有权限的设备信息
        loadEquipList() {
            sanyouAxios({
                method: 'GET',
                url:
                    '/equipment/getUserEquip?userId=' +
                    this.currentUserId +
                    '&&equipType=2'
            }).then((res) => {
                if (res.data.status === 200) {
                    this.queryEquipList = res.data.data;
                    if(this.queryEquipList.length > 0) {
                        this.dialogEquipNo = this.queryEquipList[0].equipNo;
                    }

                } else {
                    this.$message({
                        type: 'warning',
                        duration: 2000,
                        message: '获取设备信息失败!',
                    })
                }
            })
        },
        // 弹窗Table排序
        changeDialogTableSort(column) {
            console.log("column", column);
            // this.fieldName = column.prop;
            let sortingType = column.order;
            sortingType === "ascending"
                ? (this.sortMethod = "ASC")
                : (this.sortMethod = "DESC");
            //this.loadZeroPlateNo();
        },
        //每页显示数据量变更
        handleDialogSizeChange: function (val) {
            this.dialogPageSize = val;
            let currentPage = this.dialogCurrentPage;

            this.loadZeroPlateNo(currentPage,val);
        },
        //页码变更
        handleDialogCurrentChange: function (val) {
            this.dialogCurrentPage = val;
            let pageSize = this.dialogPageSize;
            this.loadZeroPlateNo(val,pageSize);
        },
        //编码更正的关闭逻辑
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        }

    },

    created() {
        this.currentUserId = localStorage.getItem('id');
        this.loadData(1);
    }
};
</script>
<style scoped>
.btnDelete {
    margin: 10px 10px;
    float: left;
}
.inputSearch {
    float: right;
    width: 200px;
    padding-right: 10px;
    padding-top: 5px;
}
.btnSearch {
    margin: 10px 10px;
    float: right;
}
.fontLabel{
    margin-left: 10px;
}

</style>
